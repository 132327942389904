import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/HealthWellnessClub/2024/vitmvisit/1.jpg';
// import p2 from 'assests/Photos/Clubs/HealthWellnessClub/2024/vitmvisit/2.jpg';
// import p3 from 'assests/Photos/Clubs/HealthWellnessClub/2024/vitmvisit/3.jpg';
// import p4 from 'assests/Photos/Clubs/HealthWellnessClub/2024/vitmvisit/4.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly3E/components/SidebarArticles/SidebarArticles';
import Sidebard2024 from '../HealthAndWellnesClub/Sidebard2024';
import Archive2023 from '../HealthAndWellnesClub/Archive2023';

const VitmVisit2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/vitmvisit/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/vitmvisit/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/vitmvisit/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/vitmvisit/4.webp`;

  
  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
      },
      {
        src: p3,
        source: p3,
        rows: 1,
        cols: 1,
      },
      {
        src: p4,
        source: p4,
        rows: 1,
        cols: 1,
      },


    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Interaction Programme on ‘Clean, Health & Resilient Bangalore at VITM 
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
     Classes: 7 to 11  Date: 05 July 2024
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Health and Wellness Club organised an “Interaction Programme with Environment Experts on ‘Clean, Health & Resilient Bangalore’ ” for the students of classes 7 to 11 on Friday, 05 July 2024. The "I am One Health – My City. My Health. Our Movement – An Environment Awareness Programme" festival was organised at VITM in collaboration with the Echo Network and Bengaluru Science & Technology Cluster (BeST), Bengaluru. Professor Shannon B Olsson, Global Director of the Echo Network, addressed the gathering about the festival and unveiled the Mass Action initiative.
       <br></br>
        Dr. Ravi Tennety, Director of BeST, spoke about the organization's various measures to enhance science and technology in the city. Mrs Kusuma, a representative of the Bruhat Bengaluru Mahanagara Palike (BBMP), discussed the measures taken to mitigate the dengue crisis in the city.
        <br></br>
        Students visited various stalls such as Molecular Solutions Care Health LLP, Saahas Zero Waste, ATREE, ARTPARK, NCBS-TIFR, TIGS, BSF, Science Gallery, Echo Network and Nivedi (ICAR), which showcased their work through presentations.
        <br></br>
        Students gained knowledge on mosquito control measures, public awareness campaigns and improved sanitation. They also reaped the benefit of Hands on activity on “Seeing Micro World", "Understanding the Immune System", "Paper model of Viruses" and "Stop the Germ Spread".

        </Typography>
      </Box>
      <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        “A clean city is a happy city. Keep your city clean for a better tomorrow”
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebard2024/>
             </Box>
             <Box marginBottom={4}>
               <Archive2023/>
             </Box>
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default VitmVisit2024;