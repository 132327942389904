import React from 'react';

import VitmVisit2024 from 'views/VitmVisit2024';

const VissionAndMissionPage = () => {
  return <VitmVisit2024/>;
};

export default VissionAndMissionPage;

